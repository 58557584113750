import React from 'react'
import Image from './Image'

import './LogoCarousel.css'

class LogoCarousel extends React.Component {
  componentDidMount() {
    let left = 0
    const childWidth = this.parent.firstElementChild.offsetWidth

    const intervalFunc = () => {
      left++
      this.parent.style.left = `-${left}px`

      if (left % childWidth === 0) {
        left = 0
        this.parent.style.left = `${left}px`
        this.parent.appendChild(this.parent.firstElementChild)
      }
    }

    this.interval = setInterval(intervalFunc, 20)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  render() {
    const { instance } = this.props

    return (
      <div className="logo-carousel">
        {instance.id === 'no' && (
          <div className="parent" ref={elm => (this.parent = elm)}>
            <div>
              <Image alt="NMD" src="logos/nmd.png" />
            </div>
            <div>
              <Image alt="Ekornes" src="logos/ekornes.png" />
            </div>
            <div>
              <Image alt="Findus" src="logos/findus.png" />
            </div>
            <div>
              <Image alt="Mesta" src="logos/mesta.png" />
            </div>
            <div>
              <Image alt="XXL" src="logos/xxl.png" />
            </div>
            <div>
              <Image alt="Toyota" src="logos/toyota.png" />
            </div>
            <div>
              <Image alt="VitusApotek" src="logos/vitusapotek.png" />
            </div>
            <div>
              <Image alt="Nammo" src="logos/nammo.png" />
            </div>
            <div>
              <Image alt="Mester Grønn" src="logos/mester-gronn.png" />
            </div>
            <div>
              <Image alt="Crayon" src="logos/crayon.png" />
            </div>
            <div>
              <Image alt="Mills" src="logos/mills.png" />
            </div>
            <div>
              <Image alt="Power" src="logos/power.png" />
            </div>
            <div>
              <Image alt="IK" src="logos/ik.png" />
            </div>
            <div>
              <Image alt="Rottefella" src="logos/rottefella.png" />
            </div>
            <div>
              <Image alt="Mitsubishi" src="logos/mitsubishi.png" />
            </div>
            <div>
              <Image alt="Heidenreich" src="logos/heidenreich.png" />
            </div>
            <div>
              <Image alt="Astrup" src="logos/astrup.png" />
            </div>
            <div>
              <Image alt="Kid" src="logos/kid.png" />
            </div>
            <div>
              <Image alt="Trelleborg" src="logos/trelleborg.png" />
            </div>
            <div>
              <Image alt="Økonomiforbundet" src="logos/okonomiforbundet.png" />
            </div>
            <div>
              <Image alt="Flexit" src="logos/flexit.png" />
            </div>
            <div>
              <Image alt="Goodtech" src="logos/goodtech.png" />
            </div>
            <div>
              <Image alt="Sparkjøp" src="logos/sparkjop.png" />
            </div>
            <div>
              <Image alt="Technip" src="logos/technip.png" />
            </div>
            <div>
              <Image alt="Norsk filminstitutt" src="logos/norskfilm.png" />
            </div>
            <div>
              <Image alt="Brødrene Dahl" src="logos/brodrenedahl.png" />
            </div>
            <div>
              <Image alt="Servi" src="logos/servi.png" />
            </div>
            <div>
              <Image alt="Randstad" src="logos/randstad.png" />
            </div>
            <div>
              <Image alt="Home Cottage" src="logos/homecottage.png" />
            </div>
            <div>
              <Image alt="WellConnection" src="logos/wellconnection.png" />
            </div>
            <div>
              <Image alt="Nokas" src="logos/nokas.png" />
            </div>
            <div>
              <Image alt="Kebony" src="logos/kebony.png" />
            </div>
            <div>
              <Image alt="Hallingplast" src="logos/hallingplast.png" />
            </div>
            <div>
              <Image alt="Gausdal" src="logos/gausdal.png" />
            </div>
            <div>
              <Image alt="Nikita" src="logos/nikita.png" />
            </div>
            <div>
              <Image alt="Man" src="logos/man.png" />
            </div>
          </div>
        )}
        {instance.id === 'se' && (
          <div className="parent" ref={elm => (this.parent = elm)}>
            <div>
              <Image alt="Crayon" src="logos/crayon.png" />
            </div>
            <div>
              <Image alt="Intersport" src="logos/intersport.png" />
            </div>
            <div>
              <Image alt="Kid" src="logos/kid.png" />
            </div>
            <div>
              <Image alt="Lohmanders" src="logos/lohmanders.png" />
            </div>
            <div>
              <Image alt="Trelleborg" src="logos/trelleborg.png" />
            </div>
            <div>
              <Image alt="Lloydsapotek" src="logos/lloydsapotek.png" />
            </div>
            <div>
              <Image alt="Økonomiforbundet" src="logos/okonomiforbundet.png" />
            </div>
            <div>
              <Image alt="XXL" src="logos/xxl.png" />
            </div>
            <div>
              <Image alt="vinge" src="logos/vinge.png" />
            </div>
            <div>
              <Image alt="Flexit" src="logos/flexit.png" />
            </div>
            <div>
              <Image alt="Goodtech" src="logos/goodtech.png" />
            </div>
            <div>
              <Image alt="Foodmark" src="logos/foodmark.png" />
            </div>
            <div>
              <Image alt="Technip" src="logos/technip.png" />
            </div>
            <div>
              <Image alt="Toyota" src="logos/toyota.png" />
            </div>
            <div>
              <Image alt="Norsk filminstitutt" src="logos/norskfilm.png" />
            </div>
            <div>
              <Image alt="Power" src="logos/power.png" />
            </div>
            <div>
              <Image alt="Brødrene Dahl" src="logos/brodrenedahl.png" />
            </div>
            <div>
              <Image alt="Randstad" src="logos/randstad.png" />
            </div>
            <div>
              <Image alt="Mesta" src="logos/mesta.png" />
            </div>
            <div>
              <Image alt="Mester Grønn" src="logos/mester-gronn.png" />
            </div>
            <div>
              <Image alt="Nammo" src="logos/nammo.png" />
            </div>
            <div>
              <Image alt="Ekornes" src="logos/ekornes.png" />
            </div>
            <div>
              <Image alt="Findus" src="logos/findus.png" />
            </div>
            <div>
              <Image alt="Rottefella" src="logos/rottefella.png" />
            </div>
            <div>
              <Image alt="Mitsubishi" src="logos/mitsubishi.png" />
            </div>
            <div>
              <Image alt="Rydbergs" src="logos/rydbergs.png" />
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default LogoCarousel
